@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/common/src/ui/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}

@layer components {
  .ad-campaigns-pagination {
    @apply absolute bottom-0 flex items-center justify-center w-screen space-x-2;
  }
}
